import request from '@/http.js'

/*****************************配送管理*****************************/
/**
 * @description 配送管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function distributionListApi (param) {
    return request.post(
        '/admin.deliveryService/getList',
        param
    )
}

/**
 * @description 门店 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function houseListApi (param) {
    return request.post(
        '/admin.deliveryService/initData',
        param
    )
}

/**
 * @description 配送管理 -- 编辑
 * @param {Object} param data {Object} 传值参数
 */
 export function distributionEditApi (param) {
    return request.post(
        '/admin.deliveryService/edit',
        param
    )
}

/**
 * @description 配送管理 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function distributionDeleteApi (param) {
    return request.post(
        '/admin.deliveryService/delete',
        param
    )
}